import {
    DEFAULT_PREVIEW_MND,
    DEFAULT_PREVIEW_POA_CEZ,
    DOCUMENT_TYPE_CONSENT_TO_SANCTION,
    DOCUMENT_TYPE_CONSENT_TO_SANCTION_MND,
    DOCUMENT_TYPE_CONTRACT_MND,
    DOCUMENT_TYPE_POA,
    DOCUMENT_TYPE_POA_CEZ,
    DOCUMENT_TYPE_POA_MND, DOCUMENT_TYPE_TERMINATION_WITHDRAWAL,
    DOCUMENT_TYPE_SURPLUS_PURCHASE_CONTRACT,
    DOCUMENT_TYPE_BAIL
} from "./index";
import { getLabel } from "../helpers/getLabel";

export const getAccordionConfig = () => [
    {
        heading: "Smlouva",
        documentType: DOCUMENT_TYPE_CONTRACT_MND,
        documentPreview: "/images/smlouva_podpis.jpg",
        label: "Prohlédněte si návrh smlouvy."
    },
    {
        documentType: DOCUMENT_TYPE_POA_MND,
        documentPreview: DEFAULT_PREVIEW_MND,
        heading: "Plná moc pro MND",
        label: "Prohlédněte si návrh plné moci."
    },
    {
        documentType: DOCUMENT_TYPE_POA_CEZ,
        documentPreview: DEFAULT_PREVIEW_POA_CEZ,
        heading: "Plná moc pro ČEZ",
        label: "Prohlédněte si návrh plné moci."
    },
    {
        documentType: DOCUMENT_TYPE_TERMINATION_WITHDRAWAL,
        documentPreview: "/images/termination-withdrawal.jpg",
        heading: "Zpětvzetí výpovědi",
        label: "Prohlédněte si návrh zpětvzetí výpovědi."
    },
    {
        documentType: DOCUMENT_TYPE_CONSENT_TO_SANCTION,
        documentPreview: "/images/consent-to-sanction.jpg",
        heading: "Souhlas se sankcí",
        label: "Prohlédněte si návrh souhlasu se sankcí."
    },
    {
        documentType: DOCUMENT_TYPE_CONSENT_TO_SANCTION_MND,
        documentPreview: "/images/consent-to-sanction.jpg",
        heading: "Souhlas se sankcí",
        label: "Prohlédněte si návrh souhlasu se sankcí."
    },
    {
        documentType: DOCUMENT_TYPE_SURPLUS_PURCHASE_CONTRACT,
        documentPreview: "/images/fve_surplus_purchase_contract.png",
        heading: "Dodatek na výkup elektřiny",
        label: "Prohlédněte si návrh smlouvy na výkup přebytků elektřiny z fotovoltaické elektrárny."
    },
    {
        documentType: DOCUMENT_TYPE_BAIL,
        documentPreview: "/images/bail.png",
        heading: "Kauce",
        label: "Prohlédněte si návrh kauce."
    },
    {
        heading: "Podpis dokumentů",
        label: `Podpisem dokumentů souhlasíte s:`
    }
];

export const filterAccordionConfig = (accordionConfig, configuration, documents = []) => {

    return accordionConfig.filter((node) => {
        return documents.map(item => item.category).includes(node.documentType) ||
            typeof node.documentType === "undefined";
    });
};

export const getPoaAccordionConfig = () => [
    {
        heading: "Osobní údaje od původního odběratele",
        label: `Doplňte prosím vaše osobní údaje pro zplnomocnění společnosti MND k vyřízení převodu ${getLabel("meraku")} na nového majitele.`
    },
    {
        documentType: DOCUMENT_TYPE_POA,
        documentPreview: DEFAULT_PREVIEW_MND,
        heading: "Plná moc pro MND",
        label: "Prohlédněte si návrh Plné moci a pokud s ní a převodem souhlasíte podepište ji."
    },
    {
        heading: "Online podpis plné moci",
        label: ""
    },
];